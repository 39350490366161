import coreService from '@/libs/api-services/core-service'
import axiosIns from '@/libs/axios'
import store from '@/store'

const getDefaultState = () => ({
  id: '',
  key: '',
  owner: '',
  shared_with: '',
  created: '',
  date_last_opened: '',
  scope: '',
  name: '',
  description: '',
  status: '',
  public: false,
  deletable: '',
  defaultSecurityClassification: '',
  lookup: {},
  linkedModels: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    setWorkspaceModel: (state, data) => {
      state.id = data.properties.id
      sessionStorage.setItem('kompozition-workspace', state.id)
      state.key = data.properties.key
      sessionStorage.setItem('kompozition-workspace-key', state.key)
      state.owner = data.properties.owner
      state.shared_with = data.properties.shared_with
      state.created = data.properties.created
      state.defaultSecurityClassification = data.properties.default_security_classification || this.$store.state.constants.defaultSecurityClassification.id
      state.date_last_opened = data.properties.date_last_opened
      state.scope = data.properties.scope
      state.name = data.properties.name
      state.description = data.properties.description
      state.status = data.properties.status
      state.public = data.properties.public
      state.deletable = data.properties.deletable
      state.lookup = data.lookup
      state.database = data.database
      if (data.database) {
        sessionStorage.setItem('X-Target', data.database)
      }
      state.linkedModels = data.linkedModels
    },
    updateModelName: (state, value) => { state.name = value },
    updateModelDescription: (state, value) => { state.description = value },
    updateModelDefaultSecurityClassification: (state, value) => { state.defaultSecurityClassification = value },
    updateModelScope: (state, value) => { state.scope = value },
    updateModelShared: (state, value) => { state.shared_with = value },
    setPublicShareState: (state, value) => { state.public = value },
    resetWorkspace: state => {
      Object.assign(state, getDefaultState())
      sessionStorage.removeItem('kompozition-workspace')
      sessionStorage.removeItem('kompozition-workspace-key')
      sessionStorage.removeItem('X-Target')
      store.state.model.database = null
    },
  },
  actions: {
    openWorkspace({ commit, state, dispatch }, modelId) {
      dispatch('closeWorkspace')
      return axiosIns
        .get(`/api/v2/models/${modelId}`)
        .then(async ({ data }) => {
          data.linkedModels = await coreService.modelApi.getLinkedModels(modelId)
          commit('setWorkspaceModel', data)
          dispatch('verticalMenu/navigationWorkspace', null, { root: true })
          console.log(`Set workspace model to "${state.name}" (${state.id})`)
        })
        .catch(e => {
          console.error(e)
          sessionStorage.removeItem('kompozition-workspace')
          sessionStorage.removeItem('kompozition-workspace-key')
        })
    },
    openTempWorkspace({ commit, state, dispatch }, model) {
      dispatch('closeWorkspace')
      sessionStorage.setItem('X-Target', model.database)
      return axiosIns
        .get(`/api/v2/models/${model.id}`)
        .then(({ data }) => {
          data.database = model.database
          commit('setWorkspaceModel', data)
          dispatch('verticalMenu/navigationWorkspace', null, { root: true })
        })
        .catch(e => {
          console.error(e)
          sessionStorage.removeItem('kompozition-workspace')
          sessionStorage.removeItem('kompozition-workspace-key')
        })
    },
    closeWorkspace({ commit, dispatch }) {
      commit('resetWorkspace')
      dispatch('canvas/clearCanvas', null, { root: true })
      dispatch('behaviours/clearBehaviours', null, { root: true })
      dispatch('domainModel/clearComponents', null, { root: true })
      dispatch('ideas/clearIdeas', null, { root: true })
      dispatch('interfaces/clearInterfaces', null, { root: true })
      dispatch('issues/clearIssues', null, { root: true })
      dispatch('comments/clearComments', null, { root: true })
      dispatch('jira/clearJira', null, { root: true })
      dispatch('notes/clearNotes', null, { root: true })
      dispatch('releases/clearReleases', null, { root: true })
      dispatch('requirements/clearRequirements', null, { root: true })
      dispatch('requirementsTableLegacy/clearRequirementsTable', null, { root: true })
      dispatch('specification/clearSpecification', null, { root: true })
      dispatch('specifications/clearSpecifications', null, { root: true })
      dispatch('verticalMenu/navigationWorkspace', null, { root: true })
    },
  },
}
